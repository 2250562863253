import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sms_survey_details_replies_filter = _resolveComponent("sms-survey-details-replies-filter")!
  const _component_sms_survey_details_replies_table = _resolveComponent("sms-survey-details-replies-table")!
  const _component_sms_survey_details_replies_empty = _resolveComponent("sms-survey-details-replies-empty")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.isEmptyMode)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createVNode(_component_sms_survey_details_replies_filter, {
            search: _ctx.search,
            "onUpdate:search": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
            idQuest: _ctx.idQuest,
            "onUpdate:idQuest": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.idQuest) = $event))
          }, null, 8, ["search", "idQuest"]),
          _createVNode(_component_sms_survey_details_replies_table, {
            headers: _ctx.tableHeaders,
            items: _ctx.filteredTableItems
          }, null, 8, ["headers", "items"])
        ], 64))
      : (_openBlock(), _createBlock(_component_sms_survey_details_replies_empty, { key: 1 }))
  ]))
}