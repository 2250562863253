
import {
  defineComponent, ref, computed, onMounted
} from 'vue'
import { useRoute } from 'vue-router'
import SmsSurveyDetailsRepliesFilter from '@/components/pages/services/smsSurvey/details/replies/SmsSurveyDetailsRepliesFilter.vue'
import SmsSurveyDetailsRepliesTable from '@/components/pages/services/smsSurvey/details/replies/SmsSurveyDetailsRepliesTable.vue'
import SmsSurveyDetailsRepliesEmpty from '@/components/pages/services/smsSurvey/details/replies/SmsSurveyDetailsRepliesEmpty.vue'
import { getTableData } from '@/services/tableService'
import { formatDate } from '@/services/dateTimeService'
import { useModes } from '@/compositions/modes'
import type { TableHeaders } from '@/definitions/shared/types'
import type { SmsSurveyRepliesLine } from '@/definitions/services/smsSurvey/types'

export default defineComponent({
  components: {
    SmsSurveyDetailsRepliesFilter,
    SmsSurveyDetailsRepliesTable,
    SmsSurveyDetailsRepliesEmpty,
  },
  setup() {
    const { isEmptyMode } = useModes()

    const route = useRoute()
    const search = ref('')

    const tableHeaders = ref<TableHeaders[]>([
      { text: 'From', value: 'from-slot' },
      { text: 'Message', value: 'message' },
      { text: 'Time received', value: 'timeReceived', format: (val: string) => formatDate(val) },
    ])

    const tableItems = ref<SmsSurveyRepliesLine[]>(getTableData('smsSurveyReplies'))

    const idQuest = ref(0)

    const filteredTableItems = computed<SmsSurveyRepliesLine[]>(() => tableItems.value
      .filter((item) => item.idQuest === idQuest.value)
      .filter((item) => !search.value || item.message.toLowerCase().includes(search.value.toLowerCase()))
    )

    onMounted(() => {
      if (route.params.idQuest && !isNaN(Number(route.params.idQuest))) {
        idQuest.value = Number(route.params.idQuest)
      }
    })

    return {
      search,
      tableHeaders,
      filteredTableItems,
      idQuest,
      isEmptyMode,
    }
  },
})
