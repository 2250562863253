
import {
  defineComponent, ref, computed, toRefs
} from 'vue'
import TopFilter from '@/components/layout/topFilter/TopFilter.vue'
import DateFilterDropdown from '@/components/layout/topFilter/dateFilter/DateFilterDropdown.vue'
import DateFilterCustom from '@/components/layout/topFilter/dateFilter/DateFilterCustom.vue'
import { tmAutoSizeIgnoreClass } from '@/definitions/shared/autoSize/data'

export default defineComponent({
  components: {
    TopFilter,
    DateFilterDropdown,
    DateFilterCustom,
  },
  props: {
    idQuest: {
      type: Number,
      required: true,
    },
  },
  emits: ['update:idQuest'],
  setup(props, { emit }) {
    const { idQuest } = toRefs(props)
    const dateFilter = ref('all')
    const customDate = ref()

    const questOptions = ref([
      'Question 1: Hello {First name}! Would you like?',
      'Question 2: Hi {First name}! What are you drinking?',
      'Question 3: Hello {First name}! What do you hate?',
      'Question 4: Hi {First name}! When are you going on vacation?',
    ])

    const quest = computed({
      set(newValue: string) {
        const index = questOptions.value.indexOf(newValue)
        emit('update:idQuest', index)
      },
      get(): string {
        return questOptions.value[idQuest.value]
      },
    })

    return {
      dateFilter,
      customDate,
      tmAutoSizeIgnoreClass,
      questOptions,
      quest,
    }
  },
})
