import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_sms_recipient = _resolveComponent("tm-sms-recipient")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_sms_survey_details_replies_dropdown = _resolveComponent("sms-survey-details-replies-dropdown")!
  const _component_tm_table = _resolveComponent("tm-table")!

  return (_openBlock(), _createBlock(_component_tm_table, {
    headers: _ctx.headers,
    items: _ctx.items
  }, {
    "body-cell-from-slot": _withCtx((props) => [
      _createVNode(_component_router_link, {
        to: { name: 'base.history.receivedSms.details', params: { id: 'f10cc724-edab-44fc-b607-6e71374c5f6b' } },
        class: "d-flex align-center font-color-inherit font-weight-normal blue-on-hover"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_tm_sms_recipient, {
            content: props.row.from
          }, null, 8, ["content"])
        ]),
        _: 2
      }, 1032, ["to"])
    ]),
    "body-cell-actions": _withCtx(() => [
      _createVNode(_component_sms_survey_details_replies_dropdown)
    ]),
    _: 1
  }, 8, ["headers", "items"]))
}