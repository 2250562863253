import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_empty_state = _resolveComponent("tm-empty-state")!

  return (_openBlock(), _createBlock(_component_tm_empty_state, {
    icon: "chat",
    title: "There are no replies to this survey yet",
    description: "Once people reply to your survey, you will start seeing their responses here.",
    "background-lift": ""
  }))
}