
import type { PropType } from 'vue'
import {
  defineComponent
} from 'vue'
import TmTable from '@/components/shared/table/TmTable.vue'
import TmSmsRecipient from '@/components/shared/TmSmsRecipient.vue'
import SmsSurveyDetailsRepliesDropdown from '@/components/pages/services/smsSurvey/details/replies/SmsSurveyDetailsRepliesDropdown.vue'
import type { TableHeaders } from '@/definitions/shared/types'
import type { SmsSurveyRepliesLine } from '@/definitions/services/smsSurvey/types'

export default defineComponent({
  components: {
    TmTable,
    TmSmsRecipient,
    SmsSurveyDetailsRepliesDropdown,
  },
  props: {
    headers: {
      type: Array as PropType<TableHeaders[]>,
      required: true,
    },
    items: {
      type: Array as PropType<SmsSurveyRepliesLine[]>,
      required: true,
    },
  },
})
